import React from 'react';
import logo from './logo.svg'; 
import './App.css';
import { RoutesApp } from './Routes/Route';

function App() {
  return (
    <RoutesApp />
  );
}

export default App;
