interface data{
    id:number,
    name:string,
    description:string
}

export const dataNav : data[] = [
    {
        id:1,
        name:'Vsitanos',
        description:'Previa coordinacion',
    },
    {
        id:2,
        name:'Correo',
        description:'refugiocolitasyamor@gmail.com',
    },
    {
        id:3,
        name:'Telefono',
        description:'+51 981557865',
    }
]